<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="详细信息" class="margin-bottom margin-top-xs"></el-page-header>
		<el-divider content-position="left">基本信息</el-divider>
		<div>
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="100px" class="flex flex-wrap">
					<el-form-item label="姓名" prop="real_name" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.real_name"  disabled="disabled" placeholder="请填写顾客姓名"></el-input>
						</div>
					</el-form-item>

				<!-- 	<el-form-item label="填写日期" prop="birthday">
						<div class="form-item-box">
							<el-date-picker v-model="form.birthday" type="date" placeholder="请填写日期"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</div>
					</el-form-item> -->
					<el-form-item label="填写日期" prop="created_at">
						<el-date-picker style="width: 100%;" disabled="disabled" v-model="form.created_at" type="datetime"
							format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" placeholder="填写日期">
						</el-date-picker>
					</el-form-item>

					<el-form-item label="联系电话" prop="phone"  :rules="[rules.required,rules.phone]">
						<div class="form-item-box">
							<el-input v-model="form.phone"  disabled="disabled" placeholder="请填写顾客联系电话"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="记录人">
						<div class="form-item-box">
							<el-input v-model="form.native_place"  disabled="disabled" placeholder="请填写记录人"></el-input>
						</div>
					</el-form-item>

				</el-form>
			</div>
			<el-divider content-position="left">详细资料</el-divider>

			<div class="form_box">
				<el-form ref="form" :model="form" label-width="400px" label-position="left" style="margin-left: 50px;">
					<!-- <el-form-item label="1、您是预约几次才成功？" :label="item.id+'、'+item.title"  v-for="(item,index) in itemList" :key="index"> -->
					<el-form-item label="1、您是预约几次才成功？"  v-for="(item,index) in itemList" :key="index">
						<span>{{item.value}}</span>
					</el-form-item>
					<!-- <el-form-item label="1、您是预约几次才成功？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="2、服务过程所花时间？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="3、老师是否有礼貌和笑容？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="4、是否满意老师的专业技术和手法？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="5、老师有否根据您的需要而推荐其它适合的项目或产品给您？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="6、是否满意店内的环境卫生？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="7、店内仪器设施和用品是否干净、整洁？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="8、是否满意老师的服务态度？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="9、您对门店或首发老师其它意见和建议？">
						<span>很好</span>
					</el-form-item> -->
					

				</el-form>
			</div>

		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	// 行政区划npm版本
	// import ChinaLocation from "china-location";
	// import list from "china-location/dist/location.json";
	export default {
		data() {
			return {
				rules,
				form: {
					id: this.$route.params.id,
					name: "",
					phone: '',
					operater_name: '',
					created_at: '',
				
				},
				itemList:[]
			
			};
		},
		computed: {
		
		},
		mounted() {
			this.getInfo()
		},
		methods: {
			//获取客户意详情
			getInfo() {
				this.$api.tablewx.customerQuestionnaireInfo({
					id: this.form.id
				}).then(res => {
					this.form = res.data
					this.itemList=res.data.item_list
					console.log(this.itemList)
					
				})
			},
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
	
	
	
</style>
